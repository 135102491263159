import React, { useState } from 'react';

function getFormData(form) {
  const elements = form.elements;
  let honeypot;

  const fields = Object.keys(elements).filter((k) => {
    if (elements[k].name === "honeypot") {
      honeypot = elements[k].value;
      return false;
    }
    return true;
  }).map((k) => {
    if(elements[k].name !== undefined) {
      return elements[k].name;
    } else if(elements[k].length > 0){
      return elements[k].item(0).name;
    }
  }).filter((item, pos, self) => {
    return self.indexOf(item) === pos && item;
  });

  const formData = {};
  fields.forEach((name) => {
    const element = elements[name];

    formData[name] = element.value;

    if (element.length) {
      const data = [];
      for (let i = 0; i < element.length; i++) {
        const item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses";
  formData.formGoogleSendEmail = form.dataset.email || "";

  return {data: formData, honeypot: honeypot};
}

function Contact() {
  const [buttonText, setButtonText] = useState('Submit');

  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const formData = getFormData(form);
    const data = formData.data;

    if (formData.honeypot) {
      return false;
    }

    setButtonText('Please Wait...');

    const url = form.action;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4 && xhr.status === 200) {
        form.reset();
        setButtonText('Submit');
      }
    };

    const encoded = Object.keys(data).map((k) => {
      return encodeURIComponent(k) + "=" + encodeURIComponent(data[k]);
    }).join('&');

    xhr.send(encoded);
  }

  return (
    <div className="container py-5">
      <section className="text-center">
        <div className="container">
          <h1>Contact Us</h1>
          <p className="lead text-center">
            Get in touch with us.
          </p>
        </div>
      </section>
      <section className="contact-form">
      <div className="container">
          <div className="row">
            <div className="col-lg-6 mt-5">
              <h3>Send Us a Message</h3>
              <form
                onSubmit={handleSubmit}
                method="post"
                action="sendMail.php"
              >
                <div className="mb-3">
                  <label htmlFor="name" className="form-label">
                    Your Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="name"
                    placeholder="Enter your name"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">
                    Your Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    placeholder="Enter your email"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label">
                    Subject
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    id="subject"
                    placeholder="Enter the subject"
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="message" className="form-label">
                    Message
                  </label>
                  <textarea
                    className="form-control"
                    name="message"
                    id="message"
                    rows="5"
                    placeholder="Enter your message"
                  ></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={buttonText === 'Please Wait...'}>{buttonText}</button>
              </form>
            </div>
            <div className="col-lg-6 mt-5">
              <h2>Visit Us</h2>
              <p>We are located at:</p>
              <p>Samuel Town, Waterloo, Freetown</p>
              <h3>Contact Information</h3>
              <p>
                Email:{" "}
                <a href="info@kotintri.sl">
                  info@kotintri.sl
                </a>
              </p>
              <p>
                Phone: <a href="tel:++23278162217">+232 78 162217</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
