import React from 'react';
import paintingServices from '../components/assets/painting-services.jpg';
import plumbingServices from '../components/assets/plumbing-services.jpg';
import electricServices from '../components/assets/electric-services.jpg';

const Services = () => {
  
  return (
    <div className="container py-5">
      <section className="hero-section text-center" id="services">
        <div className="container">
          <div className="transparent-bg">
            <h1 className="text-center">Services Center</h1>
            <p className="lead text-center">Get quality services in Freetown.</p>
          </div>
        </div>
      </section>
      <section className="services-section services-overview" id="painting">
        <div className="container py-5">
          <h2>Painting</h2>
          <img className='image' src={paintingServices} alt="Painting" />
          <p>Coming Soon</p>
        </div>
      </section>
      <section className="services-section services-overview" id="plumbing">
        <div className="container">
          <h2>Plumbing</h2>
          <img className='image' src={plumbingServices} alt="Plumbing" />
          <p>Coming Soon</p>
        </div>
      </section>
      <section className="services-section services-overview" id="electrical">
        <div className="container py-5">
          <h2>Electrical</h2>
          <img className='image' src={electricServices} alt="Electrical" />
          <p>Coming Soon</p>
        </div>
      </section>
    </div>
  );
};

export default Services;
