import React from 'react';
import './Footer.css'; // Add a custom CSS file for styling

const Footer = () => {
  return (
    <footer id="footer">
        <div className="container">
        <div className="copyright">
            © Copyright <strong><span>KotinTri 2024</span></strong>. All Rights Reserved
        </div>
        <div className="credits">
            Designed by <a href="https://mackitechsolutions.com/">MackiTech Solutions</a>
        </div>
        </div>
    </footer>
  );
};

export default Footer;
