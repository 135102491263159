import React from 'react';
import History from '../components/assets/history.jpg';
import riverNo2 from '../components/assets/river-no2.jpg';

const Information = () => {
  
  return (
    <div className="container py-5">
      <section className="hero-section text-center" id="information">
        <div className="container">
          <div className="transparent-bg">
            <h1 className="text-center">Information</h1>
            <p className="lead text-center">Get valuable information about Freetown.</p>
          </div>
        </div>
      </section>
      <section className="information-section information-overview" id="history">
        <div className="container py-5">
          <h2>History</h2>
          <p>Freetown, the capital city of Sierra Leone, was established on March 11, 1792, by 1,196 African
Americans under the guidance of British abolitionist Lieutenant John Clarkson. These settlers
were freed slaves from Nova Scotia, the United States, and Great Britain. Initially, the settlement
faced challenges from disease and hostility from the indigenous population. However, it grew to
become a significant colony for freed slaves, also known as the Sierra Leone Creole people, who
intermarried and formed a unique cultural group.</p>
          <img className='image' src={History} alt="Lumly Beach" />
          <p>Today, Freetown is a vibrant city with a bustling nightlife, numerous markets, and ongoing
development. However, it also faces challenges such as crime and the legacy of the civil war that
ended in 2002. The city is a melting pot of different cultures and traditions, reflecting its
historical roots and modern dynamism​.</p>
        </div>
      </section>
      <section className="information-section information-overview" id="attractions">
        <div className="container">
        <h2>Attractions</h2>
        <p>Freetown boasts a variety of attractions that cater to both residents and visitors. Here are some highlights:</p>
        <ul>
            <li><strong>Cotton Tree:</strong>: The Cotton Tree is a historic symbol in Freetown, Sierra Leone. It is a large
kapok tree located in the center of the city, which has stood for centuries and is a symbol
of freedom for the descendants of freed slaves who settled in Freetown.</li>
            <li><strong>Sierra Leone National Museum:</strong>: Located in Freetown, it showcases the country&#39;s
cultural heritage, including historical artifacts, traditional crafts, and exhibits on the
history of Sierra Leone.</li>
            <li><strong>Tacugama Chimpanzee Sanctuary</strong>: Located just outside Freetown in the Western Area
Peninsula National Park. It is a sanctuary for rescued and orphaned chimpanzees and
offers guided tours and educational programs on chimpanzee conservation.</li>
            <li><strong>No. 2 River Beach</strong>: Known for its stunning natural beauty, this beach is located along the
No. 2 River and is a favorite destination for both locals and tourists. It offers pristine
sands, clear waters, and a tranquil atmosphere. It is a great spot for swimming,
sunbathing, and picnics.</li>
        </ul>
        <img className='image' src={riverNo2} alt="River No. 2" />
        <p>Whether you're looking for outdoor activities, cultural experiences, or family-friendly events, Freetown has something to offer everyone.</p>
        </div>
      </section>
      <section className="information-section information-overview" id="transportation">
        <div className="container py-5">
        <h2>Transportation</h2>
        <p>Freetown provides several transportation methods for its residents and visitors, ranging from
        public buses to private vehicles. Here&#39;s an overview of the main transportation options available:</p>
        <ul>
            <li><strong>Public Buses</strong>: Freetown has recently seen the introduction of 50 new public service buses as part of the
Resilient Urban initiative, a collaboration between the World Bank and the Sierra Leone
Government. These buses are designed to offer safe, reliable, and affordable transportation across
key routes in the city. They are managed by a new company that includes existing local drivers,
ensuring continuity and employment​​.</li>
            <li><strong>Poda Poda</strong>: Poda Poda are minivans typically used for public transportation in Freetown. These vehicles are
often overcrowded but remain the most economical way to travel around the city. The apprentice
(assistant to the driver) announces the destination, and passengers can board and alight at various
points along the route for a fixed fare. Riding in a Poda Poda can be a unique cultural experience
as they often serve as informal forums for public discussions​.</li>
            <li><strong>Keke (Tuk-Tuk)</strong>: Keke, or three-wheeled vehicles, provide a comfortable and slightly more expensive alternative
to taxis and Poda Poda. They can carry up to three passengers and are known for their ability to
navigate through traffic easily. Like taxis, Keke can be hailed on the street and offer a breezy and
convenient way to travel around the city​.</li>
            <li><strong>Private Vehicles</strong>: For those seeking the highest level of comfort and reliability, private vehicle services are
available. These services offer air-conditioned cars with professional drivers, and they can be
hired for single trips or extended periods. While more expensive, private vehicles provide a
hassle-free and efficient travel option​.</li>
        </ul>
        </div>
      </section>
    </div>
  );
};

export default Information;
