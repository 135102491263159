import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css'; // Add a custom CSS file for styling

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleCloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <header className="sticky-top">
      <nav className="navbar navbar-expand-sm navbar-light">
        <div className="container">
          <Link className="navbar-brand" to="/" onClick={handleCloseMenu}><span className='titleLogo'>Kotin<img alt="T" id="logo" src="icons/logo.png" />ri</span></Link>
          <button 
            className={`navbar-toggler ${isOpen ? 'open' : ''}`} 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded={isOpen ? "true" : "false"} 
            aria-label="Toggle navigation" 
            onClick={handleToggle}
          >
            <svg className={`ham hamRotate ham4 ${isOpen ? 'active' : ''}`} viewBox="0 0 100 100" width="50">
              <path
                    className="line top"
                    d="m 70,33 h -40 c 0,0 -8.5,-0.149796 -8.5,8.5 0,8.649796 8.5,8.5 8.5,8.5 h 20 v -20" />
              <path
                    className="line middle"
                    d="m 70,50 h -40" />
              <path
                    className="line bottom"
                    d="m 30,67 h 40 c 0,0 8.5,0.149796 8.5,-8.5 0,-8.649796 -8.5,-8.5 -8.5,-8.5 h -20 v 20" />
            </svg>
          </button>
          <div className={`collapse navbar-collapse ${isOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <Link className="nav-link" to="/" onClick={handleCloseMenu}>Home</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/information" onClick={handleCloseMenu}>Information</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/shop" onClick={handleCloseMenu}>Shop</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/services" onClick={handleCloseMenu}>Services</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact" onClick={handleCloseMenu}>Contact</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
