import React from 'react';
import { Link } from 'react-router-dom';
import history from '../components/assets/history.jpg';
import attractions from '../components/assets/attractions.jpg';
import transportation from '../components/assets/transportation.jpg';
import productCategories from '../components/assets/product-categories.jpg';
import featuredProducts from '../components/assets/featured-products.jpg';
import specialOffer from '../components/assets/special-offer.jpg';
import paintingServices from '../components/assets/painting-services.jpg';
import plumbingServices from '../components/assets/plumbing-services.jpg';
import electricServices from '../components/assets/electric-services.jpg';


const Home = () => {

  return (
    <div>
      <section className="jumbotron text-center">
        <div className="container transparent-bg py-5">
          <div>
            <h1>
              Discover Freetown
            </h1>
            <h2>
              Find valuable information, quality products and services
            </h2>
            <Link
              className="btn btn-primary btn-lg"
              to="/information"
            >
              Learn More
            </Link>
          </div>
        </div>
      </section>

      <section className="container py-5">
        <h2 className="text-center">About Freetown</h2>
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={history} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">History</h5>
                <p className="card-text">Freetown has a rich history dating back to 1792...</p>
                <Link to="/information#history" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={attractions} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Attractions</h5>
                <p className="card-text">Explore the local attractions including...</p>
                <Link to="/information#attractions" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={transportation} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Transportation</h5>
                <p className="card-text">View transportation options including...</p>
                <Link to="/information#transportation" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>

        </div>
      </section>
      
      <section className="container">
        <h2 className="text-center">Online Shop</h2>
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={productCategories} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Categories</h5>
                <p className="card-text">Browse our product categories...</p>
                <Link to="/shop#categories" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={featuredProducts} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Featured Products</h5>
                <p className="card-text">Check out our featured products...</p>
                <Link to="/shop#featured" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={specialOffer} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Special Offers</h5>
                <p className="card-text">Don't miss our special offers...</p>
                <Link to="/shop#offers" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>

        </div>
      </section>
      
      <section className="container py-5">
        <h2 className="text-center">Services Center</h2>
        <div className="row mt-3">
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={paintingServices} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Painting</h5>
                <p className="card-text">The best painting services in town...</p>
                <Link to="/services#painting" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={plumbingServices} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Plumbing</h5>
                <p className="card-text">Reliable plumbing services in town...</p>
                <Link to="/services#plumbing" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>
          
          <div className="col-md-4">
            <div className="card mb-4 shadow-sm">
              <img src={electricServices} className="card-img-top" alt="City Information" />
              <div className="card-body">
                <h5 className="card-title">Electrical</h5>
                <p className="card-text">Certified electricians in town...</p>
                <Link to="/services#electrical" className="btn btn-success">Explore</Link>
              </div>
            </div>
          </div>

        </div>
      </section>
      
    </div>
  );
};

export default Home;
