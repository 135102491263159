import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Home from './pages/Home';
import Information from './pages/Information';
import Shop from './pages/Shop';
import Services from './pages/Services';
import Contact from './pages/Contact';
import History from './pages/History';
import Attractions from './pages/Attractions';
import Transportation from './pages/Transportation';
import './App.css'

function App() {
  return (
    <div>
      <Header />
      <main role="main">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/information" element={<Information />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/services" element={<Services />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/history" element={<History />} />
          <Route path="/attractions" element={<Attractions />} />
          <Route path="/transportation" element={<Transportation />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;