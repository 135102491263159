import React from 'react';
import productCategories from '../components/assets/product-categories.jpg';
import featuredProducts from '../components/assets/featured-products.jpg';
import specialOffer from '../components/assets/special-offer.jpg';

const Shop = () => {
  
  return (
    <div className="container py-5">
      <section className="hero-section text-center" id="shop">
        <div className="container">
          <div className="transparent-bg">
            <h1 className="text-center">Online Shop</h1>
            <p className="lead text-center">Get quality products in Freetown.</p>
          </div>
        </div>
      </section>
      <section className="shop-section shop-overview" id="history">
        <div className="container py-5">
          <h2>Categories</h2>
          <img className='image' src={productCategories} alt="Product Categories" />
          <p>Coming Soon</p>
        </div>
      </section>
      <section className="shop-section shop-overview" id="attractions">
        <div className="container">
          <h2>Featured Products</h2>
          <img className='image' src={featuredProducts} alt="River No. 2" />
          <p>Coming Soon</p>
        </div>
      </section>
      <section className="shop-section shop-overview" id="transportation">
        <div className="container py-5">
          <h2>Special Offers</h2>
          <img className='image' src={specialOffer} alt="River No. 2" />
          <p>Coming Soon</p>
        </div>
      </section>
    </div>
  );
};

export default Shop;
